import {useState} from "react";
import {DateTime, Interval} from "luxon";

const useDateRange = (begin = null, end = null, travel_days_before = 0, travel_days_after = 0) => {
  const [dates, setDates] = useState({
    begin: begin ? DateTime.fromISO(begin) : null,
    arrive: begin ? DateTime.fromISO(begin).minus({days: travel_days_before}) : null,
    end: end ? DateTime.fromISO(end) : null,
    depart: end ? DateTime.fromISO(end).plus({days: travel_days_after}) : null
  });

  const dateFormat = new Intl.DateTimeFormat("en", {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });

  function initialise(begin, end, travel_days_before = 0, travel_days_after = 0) {
    setDates ({
      begin: DateTime.fromISO(begin),
      arrive: DateTime.fromISO(begin).minus({days: travel_days_before}),
      end: DateTime.fromISO(end),
      depart: DateTime.fromISO(end).plus({days: travel_days_after}),
    });
  }

  function format(date) {
    return dateFormat.format(new Date(date));
  }

  function formatDates() {
    return dateFormat.formatRange(new Date(dates.begin), new Date(dates.end));
  }

  function formatDateRange(start, end) {
    return dateFormat.formatRange(new Date(start), new Date(end));
  }

  function formatTravelDates() {
    return dateFormat.formatRange(new Date(dates.arrive), new Date(dates.depart));
  }

  function formatStartDate() {
    return dates.begin.toLocaleString(DateTime.DATE_MED);
  }

  function overlaps(startA, endA, startB, endB) {
    const startAlpha = DateTime.fromISO(startA).startOf('day');
    const endAlpha = DateTime.fromISO(endA).startOf('day');
    const alpha = Interval.fromDateTimes(startAlpha, endAlpha);
    const startBravo = DateTime.fromISO(startB).startOf('day');
    const endBravo = DateTime.fromISO(endB).startOf('day');
    const bravo = Interval.fromDateTimes(startBravo, endBravo);
    return alpha.overlaps(bravo);
  }

  function difference(start, end, intervals) {
    const range = Interval.fromISO(start+'/'+end);
    const args = intervals.map(item => {
      const start = DateTime.fromISO(item.start).startOf('day');
      const end = DateTime.fromISO(item.end).endOf('day');
      return Interval.fromDateTimes(start, end);
    })
    return range.difference(...args);
  }

  function engulf(startA, endA, startB, endB) {
    const startAlpha = DateTime.fromISO(startA).startOf('day');
    const endAlpha = DateTime.fromISO(endA).endOf('day');
    const startBravo = DateTime.fromISO(startB).startOf('day');
    const endBravo = DateTime.fromISO(endB).endOf('day');
    return startAlpha <= startBravo && endAlpha >= endBravo;
  }

  function isInRange(date, start, end) {
    const day = DateTime.fromISO(date).startOf('day');
    const range = Interval.fromDateTimes(
      DateTime.fromISO(start).startOf('day'),
      DateTime.fromISO(end).endOf('day')
    );

    return range.contains(day);
  }

  function isAfter(startA, endA, startB, endB) {
    const startAlpha = DateTime.fromISO(startA).startOf('day');
    const endAlpha = DateTime.fromISO(endA).endOf('day');
    const startBravo = DateTime.fromISO(startB).startOf('day');
    const endBravo = DateTime.fromISO(endB).endOf('day');

    return startAlpha > startBravo || endAlpha > endBravo;
  }

  function minus(date, travelDays) {
    return DateTime.fromISO(date).minus({days: travelDays}).toISODate();
  }

  function plus(date, travelDays) {
    return DateTime.fromISO(date).plus({days: travelDays}).toISODate();
  }

  function isSameDay(dateA, dateB) {
    const alpha = DateTime.fromISO(dateA).startOf('day');
    const beta = DateTime.fromISO(dateB).startOf('day');
    return alpha.ts === beta.ts;
  }

  return {
    format,
    dateFormat,
    begin: dates.begin,
    arrive: dates.arrive,
    end: dates.end,
    depart: dates.depart,
    initialise,
    formatDates,
    formatDateRange,
    formatTravelDates,
    formatStartDate,
    overlaps,
    difference,
    engulf,
    isInRange,
    isAfter,
    minus,
    plus,
    isSameDay
  }
}

export default useDateRange;