import React, {useEffect, useRef} from "react";
import useAxios from "../../api/useAxios";
import {useSelector} from "react-redux";
import NotesPanel from "../NotesPanel/NotesPanel";

import {
  addAccommodationNote,
  updateAccommodationNote,
  deleteAccommodationNote
} from "./redux/accommodationSlice";

export function AccommodationNotesForm({project, uuid}) {
  const lodgings = useSelector(state => state.accommodation.lodgings);
  const lodging = lodgings.find(item => item.uuid === uuid);

  const PersonnelApi = useAxios();

  const messageRef = useRef();

  useEffect(() => {
    if (messageRef.current) {
      messageRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'nearest'
      })
    }
  }, [lodging])

  function handleSave(formData) {
    const requestConfig = new FormData();
    requestConfig.append('uuid', uuid);
    for (const [key, value] of Object.entries(formData)) {
      requestConfig.append(`${key}`, value);
    }

    PersonnelApi.axiosRequest({
        method: 'post',
        url: `/en/axios/projects/accommodation/notes/${project.id}`,
        requestConfig: requestConfig
      },
      response => addAccommodationNote(response.data)
    ).then();
  }

  function handleUpdate(formData) {
    const requestConfig = new FormData();
    requestConfig.append('uuid', uuid);
    for (const [key, value] of Object.entries(formData)) {
      requestConfig.append(`${key}`, value);
    }

    PersonnelApi.axiosRequest({
        method: 'post',
        url: `/en/axios/projects/accommodation/note/${formData.id}`,
        requestConfig: requestConfig
      },
      response => updateAccommodationNote(response.data)
    ).then();
  }

  function handleDelete(id) {
    PersonnelApi.axiosRequest({
        method: 'delete',
        url: `/en/axios/projects/accommodation/note/${id}`,
      },
      response => deleteAccommodationNote({...response.data, uuid})
    ).then();
  }

  return (
    <div className="container p-3">
      {PersonnelApi.error && (
        <div className="alert alert-danger alert-block">
          <button type="button" className="close" onClick={PersonnelApi.clearAxiosError}>×</button>
          <span style={{whiteSpace: "pre-line"}}>{PersonnelApi.error}</span>
        </div>
      )}
      <NotesPanel
        notes={lodging.notes}
        onUpdate={handleUpdate}
        onSave={handleSave}
        onDelete={handleDelete}
      />
    </div>
  );
}