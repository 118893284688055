import React, {useEffect, useRef, useState} from "react";
import {DateTime} from "luxon";
import EditIcon from "@mui/icons-material/Edit";
import classes from "../../ui/css/ModalForm.module.css";
import BlockIcon from "@mui/icons-material/Block";
import AttachFileOutlinedIcon from "@mui/icons-material/AttachFileOutlined";
import {Tooltip} from "@mui/material";
import DocumentScannerOutlinedIcon from "@mui/icons-material/DocumentScannerOutlined";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import ModalImage from "../../ui/ModalImage.js";
import ModalForm from "../../ui/ModalForm.js";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import dndClasses from "./css/dragNdrop.module.css";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";

function NotesPanel({notes, viewed, onUpdate, onSave, onDelete}) {
  const [mode, setMode] = useState('Add');
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [showImage, setShowImage] = useState(false);
  const [dragOver, setDragOver] = useState(false);
  const [formData, setFormData] = useState({})
  const fileInputRef = useRef();
  const noteInputRef = useRef();

  useEffect(() => {
    fileInputRef.current.value = null;
    noteInputRef.current.value = null;
    noteInputRef.current.focus();
  }, [notes]);

  function handleChange(event) {
    setFormData({...formData, upload: event.target.files[0]});
  }

  function handleDragEnter(event) {
    setDragOver(event.target);
  }

  function handleDragLeave(event) {
    if (dragOver === event.target) {
      setDragOver(false);
    }
  }

  function handleDragOver(event) {
    event.preventDefault();
  }

  function handleDrop(event) {
    event.preventDefault();
    setFormData({...formData, upload: event.dataTransfer.files[0]});
    setDragOver(false);
  }

  function handleEditNote(note) {
    setMode('Update');
    setFormData({id: note.id, note_text: note.note_text});
    fileInputRef.current.value = null;
    noteInputRef.current.focus();
  }

  function handleSave() {
    onSave(formData)
    setFormData({});
    fileInputRef.current.value = null;
  }

  function handleUpdate() {
    onUpdate(formData)
    setMode('Add');
    setFormData({});
    fileInputRef.current.value = null;
  }

  function handleDelete() {
    onDelete(confirmDelete.id)
    setMode('Add');
    setFormData({});
    setConfirmDelete(false);
    fileInputRef.current.value = null;
  }

  function NewPost(date) {
    if (viewed) {
      if (viewed < date) {
        return <small className="badge badge-danger mr-2">New</small>
      }
    }

    return null;
  }

  return (
    <div>
      <div
        style={{
          height: "250px",
          maxHeight: "250px",
          overflowY: "scroll",
          border: "1px solid #cccccc",
          borderRadius: "5px",
          padding: "5px"
        }}
      >
        {notes?.length === 0
          ?
          <div className="d-flex justify-content-center align-items-center p-1" style={{height: "100px"}}>
            There are no notes available to display
          </div>
          :
          notes.map(item => {
            return (
              <div
                key={item.id}
                className="d-flex flex-column p-1 mb-1"
                style={{border: "1px solid #cccccc", borderRadius: "5px", backgroundColor: "#eeeeee"}}
              >
                <div className="d-flex justify-content-between align-items-center">
                  <span style={{color: "#888888", fontSize: "10px"}}>
                    {NewPost(item.created_at)}
                    Posted by {item.user_name} {DateTime.fromISO(item.created_at).toRelative()}
                  </span>
                  <span>
                    <EditIcon
                      onClick={() => handleEditNote(item)}
                      className={classes.iconButton}
                      sx={{fontSize: 22}}
                    />
                    <Tooltip title="Delete">
                      <BlockIcon
                        onClick={() => setConfirmDelete(item)}
                        className={classes.iconButton}
                        sx={{fontSize: 22, color: "red"}}
                      />
                    </Tooltip>
                  </span>
                </div>
                <div className="px-2" style={{whiteSpace: 'pre-line'}}>
                  {item.note_text}
                </div>
                <div>
                  {
                    item.filename && (
                      <div className="d-flex align-items-center justify-content-end">
                        <strong>
                          <AttachFileOutlinedIcon sx={{fontSize: 18}}/>
                        </strong>
                        <strong className="mr-1">
                          {item.filename}
                        </strong>
                        {item.scan && (
                          <div>
                            <Tooltip title="View Document">
                              <a className="ml-1" href="#">
                                <DocumentScannerOutlinedIcon
                                  className={classes.iconButton}
                                  sx={{fontSize: 22}}
                                  onClick={() => setShowImage(item)}
                                />
                              </a>
                            </Tooltip>
                          </div>
                        )}
                        <Tooltip title="Download">
                          <a className="ml-1"
                             href={`/en/storage/download/${item.path}?file=${item.filename}`}>
                            <CloudDownloadOutlinedIcon
                              className={classes.iconButton}
                              sx={{fontSize: 22}}
                            />
                          </a>
                        </Tooltip>
                      </div>
                    )}
                </div>
              </div>
            )
          })
        }
      </div>
      <div className="px-3">
        <div className="row">
          <div className="col-8">
            <label htmlFor="notes-input" className="col-form-label noselect">{mode} Note:</label>
            <textarea
              id="notes-input"
              name="notes"
              rows="3"
              className="form-control"
              autoFocus
              onChange={event => setFormData({...formData, note_text: event.target.value})}
              value={formData.note_text ?? ''}
              ref={noteInputRef}
            />
          </div>
          <div className="col">
            <label htmlFor="upload" className="col-form-label noselect">Upload File:</label>
            <div
              className={[dndClasses.dragContainer, `${dragOver ? dndClasses.active : ''}`].join(' ')}
              style={formData.upload && {backgroundColor: '#f6ffd8'}}
              onDragOver={event => handleDragOver(event)}
              onDrop={event => handleDrop(event)}
              onDragLeave={event => handleDragLeave(event)}
              onDragEnter={event => handleDragEnter(event)}
            >
              <div className={dndClasses.dragArea}>
                {
                  formData.upload ?
                    <Tooltip title={formData.upload.name}>
                      <div className={dndClasses.dragArea}>
                        <div>Ready to upload</div>
                        <UploadFileIcon sx={{fontSize: 24}}/>
                      </div>
                    </Tooltip>
                    :
                    <>
                    <div>Drag & Drop</div>
                    <div>or</div>
                  </>
                }
                <button
                  className="btn btn-outline-primary"
                  onClick={() => fileInputRef.current.click()}
                >
                  Browse File
                </button>
                <input
                  hidden
                  ref={fileInputRef}
                  type="file"
                  onChange={event => handleChange(event)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center my-2">
          {formData.upload && <div><strong>File to upload:</strong> {formData.upload.name}</div>}
        </div>
      </div>
      <div className={classes.modalFooter}>
        <div className="float-right mb-1">
          {mode === 'Update'
            ? <button onClick={handleUpdate}
                      className="btn btn-primary d-flex justify-content-center align-items-center">
              <SendOutlinedIcon className="mr-1" sx={{fontSize: 18}}/>
              Update
            </button>
            : <button onClick={handleSave}
                      className="btn btn-primary d-flex justify-content-center align-items-center">
              <SendOutlinedIcon className="mr-1" sx={{fontSize: 18}}/>
              Send
            </button>
          }
        </div>
      </div>
      {showImage &&
        <ModalImage
          onClose={setShowImage}
          src={`/en/storage/${showImage.scan}`}
          download={`/en/storage/download/${showImage.path}?file=${showImage.filename}`}
          caption={showImage.filename}
        />
      }
      {confirmDelete &&
        <ModalForm
          heading="Confirm delete"
          onClose={() => setConfirmDelete(false)}
          style={{width: '275px'}}
        >
          <div className="m-2 d-flex justify-content-center align-items-center">
            <div className="text-center">Are you sure you want to delete this
              note{confirmDelete.filename && " and the attached file"}?
            </div>
          </div>
          <div className="d-flex justify-content-around m-2">
            <button onClick={handleDelete} className="btn btn-primary" style={{width: "40%"}}>
              <CheckCircleOutlinedIcon className="mr-1" sx={{fontSize: 18}}/>
              Ok
            </button>
            <button onClick={() => setConfirmDelete(false)} className="btn btn-secondary" style={{width: "40%"}}>
              <CancelOutlinedIcon className="mr-1" sx={{fontSize: 18}}/>
              Cancel
            </button>
          </div>
        </ModalForm>
      }
    </div>
  );
}


export default NotesPanel;