import {useSelector} from "react-redux";
import {Tooltip} from "@mui/material";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import classes from "./styles/accommodation.module.css";
import Block from "@mui/icons-material/Block";
import {ListBox} from "./ListBox";
import React, {useState} from "react";
import useAxios from "../../api/useAxios";
import {removeAddress} from "./redux/accommodationSlice";
import ModalForm from "../../ui/ModalForm";
import {EditAccommodationForm} from "./EditAccommodationForm";
import {AccommodationNotesForm} from "./AccommodationNotesForm";

export function AddressPanel() {
  const PersonnelApi = useAxios();

  const project = useSelector(state => state.accommodation.project);
  const lodgings = useSelector(state => state.accommodation.lodgings);
  const [edit, setEdit] = useState({show: false, lodging: null});
  const [notes, setNotes] = useState({show: false, lodging: null});

  function onDelete(lodging) {
    if (lodging.occupants.length > 0) {
      alert("You can't delete this address while there are team members listed in it");
      return;
    }

    PersonnelApi.axiosRequest({
      method: 'delete',
      url: `/en/axios/projects/accommodation/lodgings/${project.id}?uuid=${lodging.uuid}`
    },
      response => removeAddress(response.data.uuid)
    ).then();
  }

  const listHeight = lodgings.length < 3 ? '400px' : '190px';

  function ListLodgings() {
    return lodgings.map(lodging => {
      const dateFormat = new Intl.DateTimeFormat("en", {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      });
      const dates = dateFormat.formatRange(new Date(lodging.from), new Date(lodging.to));

      const title = (
        <div>
          <div>
            <strong>{lodging.title}</strong>
            <span style={{float: 'right'}}>
              <Tooltip title="Notes">
                <InfoOutlinedIcon
                  className={classes.hover}
                  sx={{fontSize: 24, cursor: 'pointer', mx: .1}}
                  onClick={() => setNotes({show: true, lodging: lodging})}
                />
              </Tooltip>
              <Tooltip title="Edit Availability">
                <CalendarMonthIcon
                  className={classes.hover}
                  sx={{fontSize: 24, cursor: 'pointer', mx: .1}}
                  onClick={() => setEdit({show: true, lodging: lodging})}
                />
              </Tooltip>
              <Tooltip title="Remove Address">
                <Block
                  className={classes.hover}
                  sx={{fontSize: 24, color: '#FF4747', cursor: 'pointer'}}
                  onClick={() => onDelete(lodging)}
                />
              </Tooltip>
            </span>
          </div>
          <div>
            <small>{dates}</small>
          </div>
        </div>
      );

      return (
        <div key={lodging.uuid} className="col-6">
          <ListBox uuid={lodging.uuid} title={title} data={lodging.occupants} sx={{height: listHeight}}/>
        </div>
      )
    });
  }

  return (
    <>
      <ListLodgings/>
      {edit.show && (
        <ModalForm
          heading={`Edit availability at ${edit.lodging.title}`}
          onClose={() => setEdit({show: false, lodging: null})}
        >
          <div className="d-flex justify-content-center align-items-center">
            <EditAccommodationForm
              project={project}
              lodging={edit.lodging}
              onClose={() => setEdit({show: false, lodging: null})}
            />
          </div>
        </ModalForm>
      )}
      {notes.show && (
        <ModalForm
          heading={`Notes for ${notes.lodging.title}`}
          onClose={() => setNotes({show: false, lodging: null})}
        >
          <div className="d-flex justify-content-center align-items-center">
            <AccommodationNotesForm
              project={project}
              uuid={notes.lodging.uuid}
              onClose={() => setNotes({show: false, lodging: null})}
            />
          </div>
        </ModalForm>
      )}
    </>
  );
}