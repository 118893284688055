import React, {useState} from "react";
import {DateTime} from "luxon";
import classes from "../../ui/css/ModalForm.module.css";
import useAxios from "../../api/useAxios";
import {updateLodging} from "./redux/accommodationSlice";

export function EditAccommodationForm({project, lodging, onClose}) {
  const [formData, setFormData] = useState({
    uuid: lodging.uuid,
    cost: lodging.cost,
    currency: lodging.currency,
    from: lodging.from,
    to: lodging.to
  })

  const PersonnelApi = useAxios();

  function onSave() {
    console.log("EditAccommodationForm.onSave()")
    PersonnelApi.axiosRequest(
      {
        method: 'put',
        url: `/en/axios/projects/accommodation/lodgings/${project.id}`,
        requestConfig: formData
      },
      () => updateLodging(formData)
    ).finally(() => onClose());

  }

  return (
    <div className="container p-3">
      {PersonnelApi.error && (
        <div className="alert alert-danger alert-block">
          <button type="button" className="close" onClick={PersonnelApi.clearAxiosError}>×</button>
          <span style={{whiteSpace: "pre-line"}}>{PersonnelApi.error}</span>
        </div>
      )}
      <div className="row">
        <label className="text-right col-form-label whitespace-no-wrap mx-2 col-2">Cost:</label>
        <input
          id="cost"
          className="form-control col mb-3"
          type="number"
          min="0"
          step="any"
          value={formData.cost > 0 ? formData.cost.toFixed(2) / 100.0 : ''}
          placeholder="per person/night"
          onChange={(e) => setFormData({...formData, cost: Math.round(e.target.value * 100)})}
        />
        <label className="text-right col-form-label whitespace-no-wrap mx-2 col-2">Currency:</label>
        <input
          id="currency"
          className="form-control col mb-3"
          type="text"
          value={formData.currency ?? ''}
          placeholder="GBP/EUR/USD?"
          onChange={(e) => setFormData({...formData, currency: e.target.value})}
        />
      </div>
      <div className="row">
        <label className="text-right col-form-label whitespace-no-wrap mx-2 col-2">From:</label>
        <input
          className="form-control col mb-3"
          type="date"
          value={DateTime.fromISO(formData.from).toISODate()}
          min={project.arrive}
          max={project.depart}
          onChange={(e) => setFormData({...formData, from: e.target.value})}
        />
      </div>
      <div className="row">
        <label className="text-right col-form-label whitespace-no-wrap mx-2 col-2">Until:</label>
        <input
          className="form-control col mb-3"
          type="date"
          value={DateTime.fromISO(formData.to).toISODate()}
          min={project.arrive}
          max={project.depart}
          onChange={(e) => setFormData({...formData, to: e.target.value})}
        />
      </div>
      <div className={classes.modalFooter}>
        <div className="float-right">
          <button onClick={onSave} className="btn btn-primary">
            Update
          </button>
        </div>
      </div>
    </div>
  );
}